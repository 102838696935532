
import Vue from "vue"

const AsyncLoading = () => import("@/components/AsyncLoading.vue").then(m => m.default || m)
const ApiError = () => import("@/common/ApiError.vue").then(m => m.default || m)
const ClientsPills = () => import("@/components/customer/ClientsPills.vue").then(m => m.default || m)
const ClientOrganization = () => import("@/components/ClientOrganization.vue").then(m => m.default || m)

export default Vue.extend({
	name: "PersonClients",
	components: {
        AsyncLoading,
        ApiError,
        ClientsPills,
        ClientOrganization
	},
    data(): {
        loading: boolean
        error: string | null
	} {
		return {
            loading: true,
            error: null
		}
	},
    async mounted(){
        await this.getPersonClients()
        await this.setDefaultSelected()
    },
    computed: {
        errorTitle(): string {
            return "Client Organization"
        }
    },
    methods: {
        async getPersonClients(): Promise<void> {
			this.loading = true
			try {
				await this.$vStore.dispatch("context/updatePersonClients")
			} catch (e) {
				console.warn("Could not find any clients for this person", e)
                if(e instanceof Error) this.error = e.message
			}

            if(!this.isMember()) this.determineError(this.error)
			this.loading = false
		},
        async setDefaultSelected(): Promise<void> {
            if(
                this.$vStore.state.context.associatedClients.length > 0 &&
                this.$vStore.state.context.selectedClient == null){
                const first = this.$vStore.state.context.associatedClients[0]
			    if(first) await this.$vStore.dispatch("context/setClient", first)
            }
        },
        isMember(): boolean {
            if (this.$vStore.state.context.associatedClients.length == 0) return false
            return true
        },
        determineError(e: unknown){
			if(e && e instanceof Error) this.error = e.message;
			else  this.error = "$info:NotClientOrgMember"
		},
    }
    
})
